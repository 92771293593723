import React from "react";
import Poster from "./components/Poster";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="App">
      <Poster />
      <Footer />
    </div>
  );
}

export default App;
