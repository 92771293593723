import React from "react";

const Poster = () => {
  return (
    <div className="relative h-screen flex items-center justify-center">
      <img
        src="/cricket poster.jpg"
        alt="Cricket Poster"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-start p-4 md:p-8 z-10">
        <h1 className="text-4xl md:text-6xl text-white font-semibold uppercase">
          <span className="text-2xl md:text-5xl font-bold uppercase mb-4 bg-gradient-to-r from-[#FF9933] via-white to-[#138808] text-transparent bg-clip-text">
            India's
          </span>
          <br />
          Top-Ranked Sports Platform
        </h1>
        <a href="https://wa.link/ggnow" >
          <div className="bg-gradient-to-r animate-bounce border mt-6 md:mt-10 from-yellow-500 to-orange-500 text-white rounded-lg shadow-lg uppercase font-bold text-center transition-transform transform hover:scale-105 px-6 md:px-10 py-2 md:py-3">
            Learn more
          </div>
        </a>
      </div>
    </div>
  );
};

export default Poster;
