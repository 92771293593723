import React from "react";

const Footer = () => {
  return (
    <div className="bg-black flex flex-col md:flex-row justify-between items-center p-4">
      <div className="text-xl uppercase text-orange-500 flex flex-col md:flex-row gap-6 md:gap-10">
        <a href="https://wa.link/ggnow">
          <h1 className="hover:text-gray-300 cursor-pointer">watch now</h1>
        </a>
        <a href="https://wa.link/ggnow">
          {" "}
          <h1 className="hover:text-gray-300 cursor-pointer">Sign up</h1>
        </a>
        <a href="https://wa.link/ggnow">
          {" "}
          <h1 className="hover:text-gray-300 cursor-pointer">Register now</h1>
        </a>
        <a href="https://wa.link/ggnow">
          <h1 className="hover:text-gray-300 cursor-pointer">Contact us</h1>
        </a>
        <a href="https://wa.link/ggnow">
          <h1 className="hover:text-gray-300 cursor-pointer">
            Terms and conditions
          </h1>
        </a>
      </div>
      <div className="mt-4 md:mt-0 w-20 h-10">
        <img
          className="w-full h-full object-contain"
          src="/Certified Stamp - Made with PosterMyWall.png"
          alt="Certified Stamp"
        />
      </div>
    </div>
  );
};

export default Footer;
